<template>
  <div class="_w-max _pc-pad-y-75 _flex-col">
    <div class="animate__animated animate__bounceInUp">
      <AboutUs />
    </div>
    <div :class="scrollTop > 500 ? 'animate__animated animate__fadeInLeft' : ''">
      <Contact />
    </div>
    <div :class="scrollTop > 1500 ? 'animate__animated animate__fadeInRight' : ''">
      <Message />
    </div>

  </div>
</template>

<script>
import AboutUs from "@/pages/about/components/AboutUs";
import Contact from "@/pages/about/components/Contact";
import Message from "@/pages/about/components/Message";
export default {
  name: "IndexAbout",
  components: { Message, Contact, AboutUs },
  data() {
    return {
      scrollTop: 0,//页面滑动距离
    };
  },
  metaInfo: {
    title: '嘟嘟集运 - 跨国集运专家 | 海外华人留学生集运服务',
    meta: [{
      name: 'Keywords',
      content: '靠谱集运,靠谱马来西亚集运,淘宝集运,拼多多集运,抖音集运,小红书集运,京东集运,temu集运,tiktok集运,海南嘟嘟,嘟嘟快递,嘟嘟货代,嘟嘟集运,嘟嘟国际快递集运转运,嘟嘟国际集运转运,东南亚物流,东南亚集运,马来西亚空运,马来西亚集运推荐,马来西亚华人,马来西亚海运,新加坡物流,新加坡空运,新加坡集运推荐,新加坡华人,新加坡海运,中国寄马来西亚,中国寄新加坡,中国寄泰国,泰国空运,泰国集运推荐,泰国华人,泰国海运,马来西亚tng支付,马来西亚代付,集运仓、淘宝集运、集运国际快递'
    },
    {
      name: 'Description',
      content: '嘟嘟集运，您的跨国集运专家。专为海外华人和留学生提供便捷、高效的集运服务。无论您身在何处，享受家乡商品的乐趣。轻松、快捷、安全，让您的海外生活更加丰富多彩。加入我们，探索更多集运优惠和服务。'
    }]
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  methods: {
    // 获取页面滚动距离
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    },
  }
};
</script>

<style scoped></style>
