<template>
  <div class="contact_bg _w-max _pc-mar-y-45 _mb-mar-y-60  _flex-col-mid  _flex-col-bet-end wow fadeInLeft" >



    <div class="_w-max _pc-pad-x-200 _mb-pad-x-30 _flex-row-bet-mid _pc-pad-top-40 _mb-pad-y-60">
      <!--title标题-->
      <div class="_flex-row-end">
        <div  class="_pc-font-42 _mb-font-52" style="color:var(--theme-color);font-weight: 800;line-height: 0.9;">联系方式</div>
        <div class="_pc-font-21 _pc-pad-left-20 _mb-font-26 _mb-pad-left-30 _grey-99" style="font-weight: 800;">CONTACT</div>
      </div>
    </div>
    <div class="_flex-row-mid _w-max  _pc-pad-x-200 _mb-pad-x-30 _mb-flex-col" style="align-items: stretch;">
      <div class="_pc-pad-y-30 _mb-pad-bottom-60 _pc-w-690 _mb-order-1 _mb-w-max" style="">

        <div class="_w-max ">
          <!--        <div id="container" class="_pc-w-630 _pc-h-300"></div>-->
          <img src="@/assets/image/contact.png" class="_w-max" alt=""/>
        </div>
      </div>


      <div v-if="data!=''" class="_pc-font-16 _pc-pad-y-30 _pc-pad-x-50 _pc-w-690 _pos-rel _mb-w-max _mb-pad-x-0 _mb-mar-bottom-50 _flex-col-bet _flex-1" style="height: inherit;">
        
        <div class="_mb-flex-row-bet-mid _mb-pad-bottom-30 _mb-border-bottom _pc-display-none">
          <div class="_flex-row-mid _pc-pad-y-20 _mb-pad-y-5">
            <img class="icon" style="align-self:flex-start;" :src="data[0].image_input[0]" alt="">
            <div class="_flex-col-center content">
              <span class="_pc-font-18 _mb-font-24 _pc-mar-bottom-10 _mb-mar-bottom-10">{{ data[0].title }}</span>
              <span  class="_b _pc-font-24 _mb-font-28" style="line-height:1.5" v-html="data[0].content"></span>
            </div>
          </div>
          <div class="_mb-h-80 _pc-display-none" style="width:1px;background: #999;opacity: 0.2;"></div>
          <div class="_flex-row _border-bottom _pc-pad-y-20 _mb-pad-y-5 _mb-border-bottom-none">
            <img class="icon" :src="data[1].image_input[0]" alt="">
            <div class="_flex-col-center content">
              <span class="_pc-font-18 _mb-font-24 _pc-mar-bottom-10 _mb-mar-bottom-10">{{ data[1].title }}</span>
              <span  class="_b _pc-font-24 _mb-font-28" style="line-height:1.5" v-html="data[1].content"></span>
            </div>
          </div>
        </div>
        <div class="_flex-row _border-bottom _pc-pad-bottom-20 _mb-pad-y-5 _mb-pad-top-30 _mb-border-bottom-none _mb-display-none _pc-display-block">
            <img class="icon"  :src="data[0].image_input[0]" alt="" width="">
            <div class="_flex-col-center content">
              <span class="_pc-font-18 _mb-font-24 _pc-mar-bottom-10 _mb-mar-bottom-10">{{ data[0].title }}</span>
              <span class="_b _pc-font-24 _mb-font-28" style="margin:unset;"   v-html="data[0].content"></span>
            </div>
        </div>
        <div class="_flex-row _border-bottom _pc-pad-bottom-20 _mb-pad-y-5 _mb-pad-top-30 _mb-border-bottom-none _mb-display-none _pc-display-block">
            <img class="icon"  :src="data[1].image_input[0]" alt="" width="">
            <div class="_flex-col-center content">
              <span class="_pc-font-18 _mb-font-24 _pc-mar-bottom-10 _mb-mar-bottom-10">{{ data[1].title }}</span>
              <span class="_b _pc-font-24 _mb-font-28" style="margin:unset;"   v-html="data[1].content"></span>
            </div>
        </div>
        <div class="_flex-row _border-bottom _mb-pad-y-5 _mb-pad-top-30 _mb-border-bottom-none">
            <img class="icon" :src="data[2].image_input[0]" alt="" width="">
            <div class="_flex-col-center content">
              <span class="_pc-font-18 _mb-font-24 _pc-mar-bottom-10 _mb-mar-bottom-10">{{ data[2].title }}</span>
              <span class="_b _pc-font-24 _mb-font-28" style="margin:unset;"   v-html="data[2].content"></span>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data(){
    return{
      map_point:[113.372365,23.445659],
      //联系人、联系电话、联系地址
      data:''
    }
  },

  mounted(){
    // this.get_map();
    this.get_data();
  },

  methods:{
    // get_map(){
    //   var map = new BMapGL.Map('container'); // 创建Map实例
    //   map.centerAndZoom(new BMapGL.Point(this.map_point[0],this.map_point[1]), 21); // 初始化地图,设置中心点坐标和地图级别
    //   map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
    //   var myIcon = new BMapGL.Icon(require("../../../assets/image/map-ico.png"), new BMapGL.Size(50, 96));
    //   // 创建Marker标注，使用小车图标
    //   var pt = new BMapGL.Point(this.map_point[0],this.map_point[1]);
    //   var marker = new BMapGL.Marker(pt, {
    //     icon: myIcon
    //   });
    //   // 将标注添加到地图
    //   map.addOverlay(marker);
    // },
    async get_data(){
      let data = {
        page:1,
        limit:3,
        cid:28
      }
      await this.$axios.get(
        'site/get_site_newList',
        {params:data}
      ).then(res => {
        this.data = res.data.data.all
      })
    },
  },
}
</script>

<style scoped>
.contact_bg {
  background: url('../../../assets/image/contact_bg.png') no-repeat; 
  background-size:100% 100%;
}

.contact-title{
  color: #aaaaaa;
  letter-spacing: 4px;;
}
._mb-border-bottom{
  border-bottom: 1px solid rgba(153, 153, 153, .2);
}
.icon{
  width: 4.06vw;
  height: 4.06vw;
}
.content{
  padding-left: 2.08vw;
  width: calc(100% - 4.06vw);
}
@media screen and (max-width: 768px) {
  .icon{
    width: 10.67vw;
    height: 10.67vw;
  }
  .content{
    padding-left: 3.33vw;
    width: calc(100% - 10.67vw);
  }
}
</style>
